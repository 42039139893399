import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMode } from '../../actions/localStates'

import i18next from 'i18next'

class JavaDownloadPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {

	    }

	    this.redirectToJava = this.redirectToJava.bind(this)
		this.redirectToManual = this.redirectToManual.bind(this)
	}

	componentDidMount() {

	}

	redirectToManual () {
		window.open("https://docs.cipher.com.ua/x/oIPxAQ", '_blank');
	}

	redirectToJava () {
		window.open("https://java.com/ru/download/manual.jsp", '_blank');
	}

	close () {
		if (!this.props.connectionStatus.uccConnection) {
	      this.props.actions.setSelectedMode(0)
	    }
		this.props.closeJavaDownloadPopup()
	}

	render() {
		let mode = parseInt(this.props.pkLocalState.selectedMode, 10);
		return (
			<div className='popup'>
	        <div className='popup_inner' style={{width: "470px", height: "350px"}}>
				<div className="card">
	              	<div className="card-header">
	              	{
	              		mode === 1
          				? i18next.t("pk:pkcsDevicesActiveTitle")
          				: mode === 2
          				? i18next.t("pk:pkcsDevicesPassiveTitle")
          				: ""
	              	}
	              	
	              	</div>
	              	<div className="card-body">
          			<span>
          				{i18next.t("infoTitleJnlp")}
          			</span>

          			<span><a href={this.props.base.urlUCCAgent}>{i18next.t("infoTitleJnlpDownload")}</a></span>

          			<span>{i18next.t("infoTitleJava")}</span>
          			<span><a href="https://docs.cipher.com.ua/x/oIPxAQ" target="_blank" rel="noopener noreferrer">{i18next.t("infoTitleRedirectToManual")}</a></span>
          			
          			<p style={{"margin": "0px"}}></p>
          			<span>{i18next.t("infoTitleManual")}</span>
          			
          			<span><a href="https://java.com/ru/download/manual.jsp" target="_blank" rel="noopener noreferrer">Java 8</a></span><span>{i18next.t("engTitlePart")}</span>
          			<p></p>
	                <button className="btn btn-default" style={{marginTop: "10px"}} onClick={this.close.bind(this)}>{i18next.t("pk:selectTitle")}</button>
	              </div>
	            </div>
	        </div>
	    </div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        connectionStatus: state.connectionStatusReucer,
        pkLocalState: state.privateKeyReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setSelectedMode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(JavaDownloadPopup);
