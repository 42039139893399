import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import platform from 'platform';
import { connectionStatus, uccConnectionStatus, cloudConnectionStatus, getJNLP } from '../actions/api/';
import i18next from 'i18next'
import { getLanguage, getLocaleResourcePath, languageSwitchItem } from '../actions/locales'
import { init } from '../actions/stringEncodersFuncs'
import {  setUccConnectionStatus, setCloudConnectionStatus, setIsMobileDevice, setMobileDeviceMode,
		  setVerifyDSOptionsExpand, setVerifyDSExpand, setVerifyDSTextExpand, setCreateDSOptionsExpand,
		  setCreateDSExpand, setCreateDSTextExpand, setEncryptOptionsExpand, setEncryptExpand, setEncryptTextExpand,
		  setEncryptCertExpand,	setDecryptExpand, setDecryptTextExpand, setCreateDSAddToExistExpand, setUosAgentNativeBuilds } from '../actions/localStates'
import { getURLs, getVersion, getNativeBuildsInfo } from '../actions/api'
import { setURLs, setVersion } from '../actions/localStates'
import HeaderContainer from './HeaderContainer';
import BodyContainer from './BodyContainer';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import '../css/sjwsa-client-0.1.css';

class App extends Component {

	constructor(props) {
    super(props);

		this.showBrowserInfo = this.showBrowserInfo.bind(this)
		this.javaVersion = this.javaVersion.bind(this)
		this.goToMainSite = this.goToMainSite.bind(this)
	}

	componentDidMount() {
		let _this = this

		this.checkConnection = () => {
			_this.props.actions.connectionStatus(_this.props.baseUrls.urlUCC, _this.props.baseUrls.urlCloud, _this.props.mobileModeReducer.isMobileDevice, _this.props.baseUrls.token, _this.props.privateKeyReducer.mobileDSMode)

			if (!_this.props.mobileModeReducer.isMobileDevice) {
				_this.props.actions.uccConnectionStatus(_this.props.baseUrls.urlUCC)
				.then((response) => {
					this.props.actions.setUccConnectionStatus(response)
				});
			}

			_this.props.actions.cloudConnectionStatus(_this.props.baseUrls.urlCloud, _this.props.baseUrls.token)
			.then((response) => {
				this.props.actions.setCloudConnectionStatus(response)
			});
			setTimeout(this.checkConnection, 5000);
		}
		i18next.changeLanguage('uk');

		this.props.actions.getURLs()
		.then((response) => {
			_this.props.actions.setURLs(response)
			if (response.serviceURLs.uosAgentNativeBuilds !== undefined && response.serviceURLs.uosAgentNativeBuilds.baseUrl !== undefined && response.serviceURLs.uosAgentNativeBuilds.baseUrl.length > 0) {
				_this.props.actions.getNativeBuildsInfo(response.serviceURLs.uosAgentNativeBuilds.baseUrl)
				.then((response) => {

					

					if (response !== null && response !== undefined) {
						var reader = new FileReader();
				        reader.readAsBinaryString(response);
				        reader.onload = function (evt) {
				            var data = evt.target.result;
				            var arrayOfStrings = data.split("\n");
				            var sourceObject = []
				            var arrayOfSplittedStrings;

				            for (var i = 0; i < arrayOfStrings.length; i += 1) {
				            	arrayOfSplittedStrings = arrayOfStrings[i].split("=");
				            	if (arrayOfSplittedStrings[0].length > 0) {
				            		sourceObject.push({name: arrayOfSplittedStrings[0], url: _this.props.baseUrls.uosAgentNativeBuilds.baseUrl + "/" + arrayOfSplittedStrings[1]})
				            	}
				            	
				            }
				            _this.props.actions.setUosAgentNativeBuilds(sourceObject)
				        }
				        reader.onerror = function (evt) {
				            document.getElementById("fileContents").innerHTML = "error reading file";
				        }
					}
					
				})
			}
			_this.checkConnection();

		})

		this.props.actions.getVersion()
		.then((response) => {
			_this.props.actions.setVersion(response)
		})

		this.props.actions.init()

		this.isMobileDevice = () => {
		    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
		}

		this.props.actions.setIsMobileDevice(this.isMobileDevice())
		this.props.actions.setMobileDeviceMode(this.isMobileDevice())

		if (this.isMobileDevice()) {
			this.props.actions.setVerifyDSOptionsExpand(false)
			this.props.actions.setVerifyDSExpand(true)
			this.props.actions.setVerifyDSTextExpand(true)
			this.props.actions.setCreateDSOptionsExpand(false)
			this.props.actions.setCreateDSExpand(true)
			this.props.actions.setCreateDSTextExpand(true)
			this.props.actions.setEncryptOptionsExpand(false)
			this.props.actions.setEncryptExpand(true)
			this.props.actions.setEncryptTextExpand(true)
			this.props.actions.setEncryptCertExpand(true)
			this.props.actions.setCreateDSAddToExistExpand(true)
			this.props.actions.setDecryptExpand(true)
			this.props.actions.setDecryptTextExpand(true)
		}
	}

	javaVersion() {
	  var result = null;

	  for(var i = 0, size = navigator.mimeTypes.length; i < size; i += 1)
	  {
	      if((result = navigator.mimeTypes[i].type.match(/^application\/x-java-applet;jpi-version=(.*)$/)) !== null)
	          return result[1];
	  }
	  return null;
	}

	showBrowserInfo() {
		alert(''
			+ 'Browser name  = ' + platform.name + '\n'
			+ 'Version  = ' + platform.version + '\n'
			+ 'OS = ' + platform.os + '\n'
			+ 'Description = ' + platform.description + '\n'
			+ 'Java enabled = ' + navigator.javaEnabled()
		)
	}

	goToMainSite () {
		window.open("https://cipher.com.ua/uk", '_blank').focus();
	}

	goToSource (url) {
	    window.open(url, '_blank').focus();
	}

	render() {
		return (
			<div>
				<div className="wrapper">
				{
					<div className={this.props.baseUrls.contrast ? "container grayscale-100" : "container"}>
						<HeaderContainer connectionStatus={this.props.connectionStatus} />
						<BodyContainer connectionStatus={this.props.connectionStatus.status} />
					</div>
				}
				{
			        this.props.baseUrls.uosAgentNativeBuilds !== undefined && this.props.baseUrls.uosAgentNativeBuilds.length > 0 && !this.props.mobileModeReducer.isMobileDevice && this.props.baseUrls.bodyIsMounted
			        ? <div className="right-download-panel">
			            {
			              this.props.baseUrls.uosAgentNativeBuilds.map((source) => {
			                var name = ""
			                name = source.name
			                if (source.name.indexOf("windows") !== -1) {
			                  name = "windows"
			                }

			                if (source.name.indexOf("linux") !== -1) {
			                  name = "linux"
			                }

			                if (source.name.indexOf("macos") !== -1) {
			                  name = "apple"
			                }

			                return <button className="btn right-download-button" onClick={this.goToSource.bind(this, source.url)}><i className={"fa " + "fa-" + name} style={{fontSize: "1.2rem"}}></i></button>
			              })
			            }
			            
			          </div>
			        : null
			    }
				</div>
				<div className="footer text-right" style={{marginRight: "15px", fontSize: "12px", color: "#ebebeb"}}>
				    <label>{i18next.t("version")} {this.props.baseUrls.version} build {this.props.baseUrls.build}</label><i className="fa fa-info-circle" onClick={this.showBrowserInfo} style={{"marginLeft": "7px", "cursor": "pointer"}}></i>
				    <label onClick={this.goToMainSite} style={{display: "block", cursor: "pointer"}}>@2024 Сайфер</label>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        connectionStatus: state.connectionStatusReucer,
        locales: state.i18n,
        localesReducer: state.localesReducer,
        baseUrls: state.base,
        mobileModeReducer: state.mobileModeReducer,
        privateKeyReducer: state.privateKeyReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    connectionStatus,
	    getLanguage,
	    getLocaleResourcePath,
	    languageSwitchItem,
	    getURLs,
	    setURLs,
	    uccConnectionStatus,
	    cloudConnectionStatus,
	    setUccConnectionStatus,
	    setCloudConnectionStatus,
	    getVersion,
	    setVersion,
	    getJNLP,
	    init,
	    setIsMobileDevice, setMobileDeviceMode,
	    setVerifyDSOptionsExpand,
		setVerifyDSExpand,
		setVerifyDSTextExpand,
		setCreateDSOptionsExpand,
		setCreateDSExpand,
		setCreateDSTextExpand,
		setEncryptOptionsExpand,
		setEncryptExpand,
		setEncryptTextExpand,
		setEncryptCertExpand,
		setDecryptExpand,
		setDecryptTextExpand,
		setCreateDSAddToExistExpand,
		getNativeBuildsInfo,
		setUosAgentNativeBuilds
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);