import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { bindActionCreators } from 'redux'
import saveAs from 'file-saver';

import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'

import { createSession, sendSessionOption, sendKeyContainer, getCertificateDataInBase64, deleteSession, sendTokenPath } from '../../actions/api'

class CertificateInfo extends Component {

  constructor(props) {
    super(props);

    this.state = {
        isLoadingSignature: false,
        isLoadingKeyAgreement: false,
        uuid: null
    }

    this.downloadSignature = this.downloadSignature.bind(this);
    this.downloadKeyAgreement = this.downloadKeyAgreement.bind(this);
  }

  componentDidMount() {
    
  }

  downloadSignature () {
    var byteCharacters, byteNumbers, blob, byteArray;
    var fileName = "signature.cer";

    if (this.props.base.isVtco) {
        byteCharacters = atob(this.props.crt.certSignature);
        byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        byteArray = new Uint8Array(byteNumbers);

        blob = new Blob([byteArray], {type: "application/octet-stream"});
        
        saveAs(blob, fileName);
        return;
    }

    let caId = {
      caId: this.props.pkLocalState.selectedKeyContainer
    }

    let settedToken = {
      keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
    }

    if (this.props.pkLocalState.keyContainer !== null) {
        if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
            let keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
            this.downloadSignatureWithParams(caId, keyPath)
        } else {
            this.downloadSignatureWithParams(caId)
        }
    } else {
        if (this.props.pkLocalState.tokenPathHSM.length > 0) {
            let keyPath = {"keyStoreUri": this.props.pkLocalState.tokenPathHSM}
            this.downloadSignatureWithParams(caId, keyPath)
        } else {
            if (this.props.pkLocalState.depositsignSelectedKey !== null) {
                let keyPath = {"keyStoreUri": this.props.pkLocalState.depositsignSelectedKey.KeyName}
                this.downloadSignatureWithParams(caId, keyPath)
            } else {
                this.downloadSignatureWithParams(caId, settedToken)
            }
        }
    }
  }

  downloadSignatureWithParams (caId, settedToken) {
    var byteCharacters, byteNumbers, blob, byteArray, url;
    var fileName = "signature.cer";

    this.setState({"isLoadingSignature": true})

    if (this.props.pkLocalState.useUccConnection) {
      url = this.props.base.urlUCC + "/api/v1"
    } else {
      url = this.props.base.urlCloud + "/api/v1"
    }

    var keyContainerPassword
    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
      const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

      const jsEncrypt = new JSEncrypt();
      jsEncrypt.setPublicKey(publicKey);

      if (this.props.pkLocalState.useTwoFactorCode) {
          keyContainerPassword = aes.encrypt(this.props.pkLocalState.keyContainerPassword, this.props.pkLocalState.twoFactorCode).toString();
      } else {
          keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.keyContainerPassword);
      }
    } else {
      keyContainerPassword = this.props.pkLocalState.keyContainerPassword
    }

    if (this.props.pkLocalState.tokenPathHSM.length > 0) {
        return this.props.actions.createSession(url, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
          .then((response) => {
            this.setState({uuid: response.ticketUuid})
            console.log(response);
          })
          .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
          .then(() => this.props.actions.getCertificateDataInBase64(url, this.state.uuid, keyContainerPassword, "signature", this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
          .then((response) => {
            console.log(response)

            if (response !== null && Object.keys(response).length > 0) {
              byteCharacters = atob(response.base64Data);
              byteNumbers = new Array(byteCharacters.length);

              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              byteArray = new Uint8Array(byteNumbers);

              blob = new Blob([byteArray], {type: "application/octet-stream"});
              saveAs(blob, fileName);
            }
          })
          .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
          .then(() => {
            this.setState({"isLoadingSignature": false})
          })
    }

    return this.props.actions.createSession(url, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
        .then((response) => {
          this.setState({uuid: response.ticketUuid})
          console.log(response);
        })
        .then(() => this.props.actions.sendSessionOption(url, this.state.uuid, caId))
        .then(() => settedToken !== undefined ? this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null) : this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
        .then(() => this.props.actions.getCertificateDataInBase64(url, this.state.uuid, keyContainerPassword, "signature", this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
        .then((response) => {
          console.log(response)

          if (response !== null && Object.keys(response).length > 0) {
            byteCharacters = atob(response.base64Data);
            byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            byteArray = new Uint8Array(byteNumbers);

            blob = new Blob([byteArray], {type: "application/octet-stream"});
            saveAs(blob, fileName);
          }
        })
        .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
        .then(() => {
          this.setState({"isLoadingSignature": false})
        })
  }

  downloadKeyAgreement () {
    var byteCharacters, byteNumbers, blob, byteArray;
    var fileName = "keyAgreement.cer";

    if (this.props.base.isVtco) {
        byteCharacters = atob(this.props.crt.certKeyAgreement);
        byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        byteArray = new Uint8Array(byteNumbers);

        blob = new Blob([byteArray], {type: "application/octet-stream"});
        
        saveAs(blob, fileName);
        return;
    }

    let caId = {
      caId: this.props.pkLocalState.selectedKeyContainer
    }

    let settedToken = {
      keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
    }

    if (this.props.pkLocalState.keyContainer !== null) {
        if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
            let keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
            this.downloadKeyAgreementWithParams(caId, keyPath)
        } else {
            this.downloadKeyAgreementWithParams(caId)
        }
    } else {
        if (this.props.pkLocalState.tokenPathHSM.length > 0) {
            let keyPath = {"keyStoreUri": this.props.pkLocalState.tokenPathHSM}
            this.downloadKeyAgreementWithParams(caId, keyPath)
        } else {
            if (this.props.pkLocalState.depositsignSelectedKey !== null) {
                let keyPath = {"keyStoreUri": this.props.pkLocalState.depositsignSelectedKey.KeyName}
                this.downloadKeyAgreementWithParams(caId, keyPath)
            } else {
                this.downloadKeyAgreementWithParams(caId, settedToken)
            }
        }
    }
  }

  downloadKeyAgreementWithParams (caId, settedToken) {
    var byteCharacters, byteNumbers, blob, byteArray, url;
    var fileName = "keyAgreement.cer";

    this.setState({"isLoadingKeyAgreement": true})

    if (this.props.pkLocalState.useUccConnection) {
      url = this.props.base.urlUCC + "/api/v1"
    } else {
      url = this.props.base.urlCloud + "/api/v1"
    }

    var keyContainerPassword
    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
      const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

      const jsEncrypt = new JSEncrypt();
      jsEncrypt.setPublicKey(publicKey);

      if (this.props.pkLocalState.useTwoFactorCode) {
          keyContainerPassword = aes.encrypt(this.props.pkLocalState.keyContainerPassword, this.props.pkLocalState.twoFactorCode).toString();
      } else {
          keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.keyContainerPassword);
      }
    } else {
      keyContainerPassword = this.props.pkLocalState.keyContainerPassword
    }

    if (this.props.pkLocalState.tokenPathHSM.length > 0) {
      return this.props.actions.createSession(url, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
        .then((response) => {
          this.setState({uuid: response.ticketUuid})
          console.log(response);
        })
        .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
        .then(() => this.props.actions.getCertificateDataInBase64(url, this.state.uuid, this.props.pkLocalState.keyContainerPassword, "keyAgreement", this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
        .then((response) => {
          console.log(response)

          if (response !== null && Object.keys(response).length > 0) {
            byteCharacters = atob(response.base64Data);
            byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            byteArray = new Uint8Array(byteNumbers);

            blob = new Blob([byteArray], {type: "application/octet-stream"});
            saveAs(blob, fileName);
          }
          
        })
        .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
        .then(() => {
          this.setState({"isLoadingKeyAgreement": false})
        })
  }

  return this.props.actions.createSession(url, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
      .then((response) => {
        this.setState({uuid: response.ticketUuid})
        console.log(response);
      })
      .then(() => this.props.actions.sendSessionOption(url, this.state.uuid, caId, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
      .then(() => settedToken !== undefined ? this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null) : this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
      .then(() => this.props.actions.getCertificateDataInBase64(url, this.state.uuid, keyContainerPassword, "keyAgreement", this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
      .then((response) => {

        console.log(response)

        if (response !== null && Object.keys(response).length > 0) {
          byteCharacters = atob(response.base64Data);
          byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          byteArray = new Uint8Array(byteNumbers);

          blob = new Blob([byteArray], {type: "application/octet-stream"});
          
          saveAs(blob, fileName);
        }
      })
      .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
      .then(() => {
        this.setState({"isLoadingKeyAgreement": false})
      })
  }

  formatDate(dateInString) {
      var localeDateOptions = {
          "day": "numeric",
          "month": "numeric",
          "year": "numeric",
          "hour": "2-digit",
          "minute": "2-digit",
          "second": "2-digit",
          "timeZoneName": "short"
      }

      return new Date(dateInString).toLocaleDateString(i18next.language, localeDateOptions);
  }

  render() {
    return (
        
        <div className="col-md-12 col-lg-8 col-sm-12 col-xs-12">
          {
            this.props.certificateInfo ?
            <div className="card" style={{"marginBottom": "10px"}}>
              <div className="card-header">
                  {i18next.t("pk:keyInfoTitle")}
                  {
                    this.state.isLoadingSignature
                    ? <i className="fa fa-spinner fa-pulse" style={{float: "right"}}></i>
                    : <i className="fa fa-download" title={i18next.t("saveTitle")} style={{"cursor": "pointer", float: "right"}} onClick={this.downloadSignature}></i>
                  }
              </div>
              {
                this.props.base.isVtco
                ? <div className="card-body left-padding-5">
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("commonName")}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.subject.commonName}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("serialNumber")}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.serialNumber}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("notBefore")}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.formatDate(this.props.certificateInfo.notBefore)}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("notAfter")}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.formatDate(this.props.certificateInfo.notAfter)}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isElectronicStamp")}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.extensions.isElectronicStamp === true ? i18next.t("yesUCase") : i18next.t("noUCase")}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isQualified")}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.extensions.isQualified === true ? i18next.t("yesUCase") : i18next.t("noUCase")}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isTemporary")}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.extensions.isTemporary === true ? i18next.t("yesUCase") : i18next.t("noUCase")}
                            </div>
                      </div>
                  </div>
                : <div className="card-body left-padding-5">
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.ownerCertificateInfo.value.ownerFullName.description}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.ownerCertificateInfo.value.ownerFullName.value}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.certificateSerialNumber.description}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.certificateSerialNumber.value}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.notBefore.description}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.formatDate(this.props.certificateInfo.notBefore.value)}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.notAfter.description}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.formatDate(this.props.certificateInfo.notAfter.value)}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.extensionsCertificateInfo.value.isElectronicStamp.description}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.extensionsCertificateInfo.value.isElectronicStamp.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.extensionsCertificateInfo.value.isQualified.description}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.extensionsCertificateInfo.value.isQualified.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}
                            </div>
                      </div>
                      <div className="input-group">
                          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.extensionsCertificateInfo.value.isTemporary.description}</label>
                            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                              {this.props.certificateInfo.extensionsCertificateInfo.value.isTemporary.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}
                            </div>
                      </div>
                  </div>
              }
            </div> : null
          }
            {
              <p></p>
            }
          {
          this.props.certificateInfoKeyAgreement ? 
            <div className="card">
                <div className="card-header">
                    {i18next.t("pk:keyInfoKeyAgreementTitle")}
                    {
                      this.state.isLoadingKeyAgreement
                      ? <i className="fa fa-spinner fa-pulse" style={{float: "right"}}></i>
                      : <i className="fa fa-download" title={i18next.t("saveTitle")} style={{"cursor": "pointer", float: "right"}} onClick={this.downloadKeyAgreement}></i>
                    }
                </div>
                {
                    this.props.base.isVtco
                    ? <div className="card-body left-padding-5">
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("commonName")}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.subject.commonName}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("serialNumber")}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.serialNumber}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("notBefore")}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.formatDate(this.props.certificateInfoKeyAgreement.notBefore)}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("notAfter")}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.formatDate(this.props.certificateInfoKeyAgreement.notAfter)}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isElectronicStamp")}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.extensions.isElectronicStamp === true ? i18next.t("yesUCase") : i18next.t("noUCase")}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isQualified")}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.extensions.isQualified === true ? i18next.t("yesUCase") : i18next.t("noUCase")}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isTemporary")}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.extensions.isTemporary === true ? i18next.t("yesUCase") : i18next.t("noUCase")}
                              </div>
                        </div>
                      </div>
                    : <div className="card-body left-padding-5">
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfoKeyAgreement.ownerCertificateInfo.value.ownerFullName.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.ownerCertificateInfo.value.ownerFullName.value}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfoKeyAgreement.certificateSerialNumber.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.certificateSerialNumber.value}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfoKeyAgreement.notBefore.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.formatDate(this.props.certificateInfoKeyAgreement.notBefore.value)}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfoKeyAgreement.notAfter.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.formatDate(this.props.certificateInfoKeyAgreement.notAfter.value)}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfoKeyAgreement.extensionsCertificateInfo.value.isElectronicStamp.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.extensionsCertificateInfo.value.isElectronicStamp.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfoKeyAgreement.extensionsCertificateInfo.value.isQualified.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.extensionsCertificateInfo.value.isQualified.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfoKeyAgreement.extensionsCertificateInfo.value.isTemporary.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfoKeyAgreement.extensionsCertificateInfo.value.isTemporary.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}
                              </div>
                        </div>
                      </div>
                }
              </div> : null
            }
        </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        localesReducer: state.localesReducer,
        language: state.localesReducer.language,
        crt: state.certificateInfo,
        certificateInfo: state.certificateInfo.certInfoSignature,
        certificateInfoKeyAgreement: state.certificateInfo.certInfoKeyAgreement,
        pkLocalState: state.privateKeyReducer,
        connectionStatus: state.connectionStatusReucer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      createSession,
      sendSessionOption,
      sendKeyContainer,
      getCertificateDataInBase64,
      deleteSession, sendTokenPath
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateInfo);