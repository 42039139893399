const pkDafaultState = {
    selectedKeyContainer: "testCipherCa",
    defaultSelectedKeyContainer: "testCipherCa",
	keyContainer: null,
	keyContainerPassword: "",
	fileKCSelected: false,
    keyContainerName: "",
    selectedTab: "CertificateInfo",
    settedTime: 15,
    isActive: false,
    certInfoKeyAgreementBase64: null,
    listCSK: null,
    passiveTokenPath: null,
    activeTokenPath: null,
    selectedMode: "0",
    activeToken: "",
    passiveToken: "",
    cleanUpForm: false,
    cleanUpAllForms: false,
    mobileDSMode: false,
    phoneNumber: "",
    fullPhoneNumber: "",
    msspId: "",
    positionId: "",
    serviceId: "",
    listMobileDSProviders: null,
    ownersOrganization: null,
    mobileSignatureCert: null,
    tokenPathHSM: "",
    depositsignUserName: "",
    depositsignKeysInfo: null,
    depositsignSelectedKey: null,
    useUccConnection: false,
    useTwoFactorCode: false,
    twoFactorCode: "",
    showPinInput: false,
    tmpPin: "",
    tmpTwoFactorCode: "",
    vtcoUserName: "",
    isTwoFactorEnable: false,
    vtcoTokenBody: "",
    vtcoKeysInfo: null,
    vtcoSelectedKey: null,
    currentUtcDateTime: null,
    publicKeyTextual: null,
    vtcoPin: "",
    vtcoTwoFactorCode: "",
    tokenInfo: null,
    integretionUrl: null
}

const privateKeyReducer = (state = pkDafaultState, { type, payload }) => {

    switch (type) {
    	case "SET_SELECTED_KEY_CONTAINER": {
		    return {
		        ...state,
		        selectedKeyContainer: payload
		    }
		}

        case "SET_MOBILE_DS_MODE": {
            return {
                ...state,
                mobileDSMode: payload
            }
        }

        case "SET_MOBILE_SIGNATURE_CERT": {
            return {
                ...state,
                mobileSignatureCert: payload
            }
        }

        case "SET_OWNERS_ORGANIZATION": {
            return {
                ...state,
                ownersOrganization: payload
            }
        }

        case "CLEAR_OWNERS_ORGANIZATION": {
            return {
                ...state,
                ownersOrganization: null
            }
        }

        case "SET_MOBILE_DS_MSSPID": {
            return {
                ...state,
                msspId: payload
            }
        }

        case "SET_MOBILE_DS_PROVIDERS": {
            return {
                ...state,
                listMobileDSProviders: payload
            }
        }

        case "CLEAR_MOBILE_DS_PROVIDERS": {
            return {
                ...state,
                listMobileDSProviders: null
            }
        }

        case "SET_FULL_PHONE_NUMBER": {
            return {
                ...state,
                fullPhoneNumber: payload
            }
        }

        case "CLEAR_FULL_PHONE_NUMBER": {
            return {
                ...state,
                fullPhoneNumber: ""
            }
        }

        case "SET_PHONE_NUMBER": {
            return {
                ...state,
                phoneNumber: payload
            }
        }

        case "CLEAR_PHONE_NUMBER": {
            return {
                ...state,
                phoneNumber: ""
            }
        }

        case "SET_POSITION_ID": {
            return {
                ...state,
                positionId: payload
            }
        }

        case "CLEAR_POSITION_ID": {
            return {
                ...state,
                positionId: ""
            }
        }

        case "SET_SERVICE_ID": {
            return {
                ...state,
                serviceId: payload
            }
        }

        case "CLEAR_SERVICE_ID": {
            return {
                ...state,
                serviceId: ""
            }
        }

		case "SET_CERT_INFO_KEY_AGREEMENT_BASE64": {
		    return {
		        ...state,
		        certInfoKeyAgreementBase64: payload
		    }
		}

        case "SET_LIST_CSK": {
            return {
                ...state,
                listCSK: payload
            }
        }

		case "CLEAR_SELECTED_KEY_CONTAINER": {
		    return {
		        ...state,
		        selectedKeyContainer: payload
		    }
		}

        case 'SET_KEY_CONTAINER': {
            return {
                ...state,
                keyContainer: payload,
                fileKCSelected: true,
                keyContainerName: payload.name
            }
        }

        case 'SET_KEY_CONTAINER_PATH': {
            return {
                ...state,
                keyContainer: payload,
                fileKCSelected: true,
                keyContainerName: payload
            }
        }

        case 'SET_SELECTED_TAB': {
            return {
                ...state,
                selectedTab: payload
            }
        }

        case 'CLEAR_KEY_CONTAINER': {
            return {
                ...state,
                keyContainer: null,
                fileKCSelected: false,
                keyContainerName: ""
            }
        }

        case 'CLEAR_KEY_CONTAINER_PATH': {
            return {
                ...state,
                keyContainer: null,
                fileKCSelected: false,
                keyContainerName: ""
            }
        }

        case 'SET_KEY_CONTAINER_PASS': {
            return {
                ...state,
                keyContainerPassword: payload
            }
        }

        case 'CLEAR_KEY_CONTAINER_PASS': {
            return {
                ...state,
                keyContainerPassword: ""
            }
        }

        case 'SET_ACTIVATION_PERIOD': {
            return {
                ...state,
                settedTime: payload
            }
        }

        case 'SET_IS_ACTIVE': {
            return {
                ...state,
                isActive: payload,
                cleanUpForm: payload
            }
        }

        case 'SET_CLEAN_UP_ALL': {
            return {
                ...state,
                cleanUpAllForms: payload
            }
        }

        case 'SET_PASSIVE_TOKEN_PATH': {
            return {
                ...state,
                passiveTokenPath: payload
            }
        }

        case 'SET_ACTIVE_TOKEN_PATH': {
            return {
                ...state,
                activeTokenPath: payload
            }
        }

        case 'SET_SELECTED_MODE': {
            return {
                ...state,
                selectedMode: payload
            }
        }

        case 'SET_ACTIVE_TOKEN': {
            return {
                ...state,
                activeToken: payload
            }
        }

        case 'SET_PASSIVE_TOKEN': {
            return {
                ...state,
                passiveToken: payload
            }
        }

        case 'SET_TOKEN_PATH_HSM': {
            return {
                ...state,
                tokenPathHSM: payload
            }
        }

        case 'CLEAR_TOKEN_PATH_HSM': {
            return {
                ...state,
                tokenPathHSM: ""
            }
        }

        case 'SET_DEPOSITSIGN_USER_NAME': {
            return {
                ...state,
                depositsignUserName: payload
            }
        }

        case 'CLEAR_DEPOSITSIGN_USER_NAME': {
            return {
                ...state,
                depositsignUserName: ""
            }
        }

        case 'SET_DEPOSITSIGN_KEYS_INFO': {
            return {
                ...state,
                depositsignKeysInfo: payload
            }
        }

        case 'CLEAR_DEPOSITSIGN_KEYS_INFO': {
            return {
                ...state,
                depositsignKeysInfo: null
            }
        }

        case 'SET_DEPOSITSIGN_SELECTED_KEY': {
            return {
                ...state,
                depositsignSelectedKey: payload
            }
        }

        case 'CLEAR_DEPOSITSIGN_SELECTED_KEY': {
            return {
                ...state,
                depositsignSelectedKey: null
            }
        }

        case 'SET_USE_UCC_CONNECTION': {
            return {
                ...state,
                useUccConnection: payload
            }
        }

        case 'SET_USE_TWO_FACTOR_CODE': {
            return {
                ...state,
                useTwoFactorCode: payload
            }
        }

        case 'SET_TWO_FACTOR_CODE': {
            return {
                ...state,
                twoFactorCode: payload
            }
        }

        case 'SET_SHOW_PIN_INPUT': {
            return {
                ...state,
                showPinInput: payload
            }
        }

        case 'SET_TMP_PIN': {
            return {
                ...state,
                tmpPin: payload
            }
        }

        case 'SET_TMP_TWO_FACTOR_CODE': {
            return {
                ...state,
                tmpTwoFactorCode: payload
            }
        }

        case 'SET_VTCO_USER_NAME': {
            return {
                ...state,
                vtcoUserName: payload
            }
        }

        case 'CLEAR_VTCO_USER_NAME': {
            return {
                ...state,
                vtcoUserName: ""
            }
        }

        case 'SET_VTCO_KEYS_INFO': {
            return {
                ...state,
                vtcoKeysInfo: payload
            }
        }

        case 'CLEAR_VTCO_KEYS_INFO': {
            return {
                ...state,
                vtcoKeysInfo: null
            }
        }

        case 'SET_VTCO_SELECTED_KEY': {
            return {
                ...state,
                vtcoSelectedKey: payload
            }
        }

        case 'CLEAR_VTCO_SELECTED_KEY': {
            return {
                ...state,
                vtcoSelectedKey: null
            }
        }

        case 'SET_TWO_FACTOR_ENABLE': {
            return {
                ...state,
                isTwoFactorEnable: payload
            }
        }

        case 'SET_VTCO_TOKEN_BODY': {
            return {
                ...state,
                vtcoTokenBody: payload
            }
        }

        case 'SET_PROTECTED_PARAMS': {
            return {
                ...state,
                currentUtcDateTime: payload.currentUtcDateTime,
                publicKeyTextual: payload.publicKeyTextual
            }
        }

        case 'CLEAR_PROTECTED_PARAMS': {
            return {
                ...state,
                currentUtcDateTime: null,
                publicKeyTextual: null
            }
        }

        case 'SET_VTCO_PIN': {
            return {
                ...state,
                vtcoPin: payload
            }
        }

        case 'SET_VTCO_TWO_FACTOR_CODE': {
            return {
                ...state,
                vtcoTwoFactorCode: payload
            }
        }

        case 'SET_TOKEN_INFO': {
            return {
                ...state,
                tokenInfo: payload
            }
        }

        case 'SET_INTEGRATION_URL': {
            return {
                ...state,
                integretionUrl: payload
            }
        }

        default:
            return state;
    }

}

export default privateKeyReducer